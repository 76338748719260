import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps, Link as RouterLink } from 'react-router-dom';
import { withStyles, createStyles, WithStyles } from '@mui/styles';
import { alpha } from '@mui/material/styles';
import { withTranslation, WithTranslation } from 'react-i18next';
import { whiteColor, blackRussianColor } from '~/styles/themes/common-styles/color';
import Button from '@mui/material/Button';
import { IStore } from '~/stores/configure-store';
import HomeIcon from '@mui/icons-material/Home';
import Layout from '~/components/common/Layout';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import CustomDataGridToolbar from '~/components/common/custom-data-grid-toolbar';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import * as AccountActions from '~/stores/actions/account-action';
import { IAccountSummaryList } from '~/types/admin-types';
import RefreshIcon from '@mui/icons-material/Refresh';
import { DataGrid, GridColumns, GridSortDirection, GridSortModel } from '@mui/x-data-grid';
import { ROWS_PER_PAGE_OPTIONS, DATA_GRID_SORTING_ORDER } from '~/constants/common';
import { OrderByEnum } from '~/enums/common';
import { ListAccountsOrderEnum } from '~/gapi/gtypes';
import { withFirebaseUser } from '~/hooks/with-firebase-auth';
import { withAdminProfile } from '~/hooks/with-admin-profile';
import { compose } from 'redux';
import { AppRoutePath } from '~/AppRouter';
import { withAuthorization } from '~/hooks/with-authorization';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';

const SEARCH_INPUT_DELAY = 300;

interface IStateProps {
  accountList: IAccountSummaryList;
  isLoading: boolean;
}

interface IDispProps {
  listAccounts: (
    args: AccountActions.QueryListAccountsArgs,
  ) => Promise<AccountActions.LIST_ACCOUNTS_RESULT_TYPE>;
}

interface Props
  extends IStateProps,
    IDispProps,
    RouteComponentProps<{}>,
    WithTranslation,
    WithStyles<typeof styles> {}

interface State {
  searchText: string;
}

class Accounts extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      searchText: '',
    };
  }

  async componentDidMount() {
    const { accountList } = this.props;

    if (accountList?.filter?.searchText) {
      this.setState({
        searchText: accountList.filter.searchText,
      });
    }

    if (!accountList.accounts) {
      try {
        await this.props.listAccounts({ page: { pageIndex: 0 } });
      } catch (error) {
        // Do nothing
      }
    }
  }

  public render() {
    const { classes, t, accountList, isLoading } = this.props;
    const { totalItems, pageIndex: page, pageSize } = accountList.pageInfo;
    const { orderBy, orderType } = accountList.order ?? {};
    const { searchText } = this.state;

    const columns: GridColumns = [
      {
        field: '#',
        type: 'actions',
        headerName: '',
        renderCell: (params) => {
          return [
            <IconButton
              key="1"
              size="small"
              component={RouterLink}
              to={`/account/${params.row.accountUuid}/info`}
            >
              <InfoIcon />
            </IconButton>,
          ];
        },
      },
      {
        field: DataGridFieldEnum.UUID,
        disableColumnMenu: true,
        filterable: false,
        sortable: false,
        headerName: t('account_UUID'),
        width: 150,
      },
      {
        field: DataGridFieldEnum.AWS_ACCOUNT_ID,
        disableColumnMenu: true,
        sortable: false,
        filterable: true,
        headerName: t('aws_account_id'),
        width: 300,
      },
      {
        field: DataGridFieldEnum.DISPLAY_NAME,
        disableColumnMenu: true,
        sortable: false,
        filterable: true,
        headerName: t('display_name'),
        width: 300,
      },
      {
        field: DataGridFieldEnum.EMAIL,
        disableColumnMenu: true,
        sortable: false,
        filterable: true,
        headerName: t('contact_email'),
        width: 350,
      },
      {
        field: DataGridFieldEnum.SERVER_NUM,
        disableColumnMenu: true,
        sortable: true,
        filterable: false,
        headerName: t('num_servers'),
        width: 150,
      },
      {
        field: DataGridFieldEnum.CREATED_AT,
        disableColumnMenu: true,
        sortable: true,
        filterable: false,
        headerName: t('create_date'),
        valueFormatter: ({ value }) => t('create_date_value', { date: value }),
        width: 200,
      },
    ];

    const items: Array<Item> = (accountList.accounts || []).map((account) => ({
      email: account.email,
      id: account.accountUuid,
      accountUuid: account.accountUuid,
      displayName: account.displayName,
      serverNum: account.serverNum,
      uuid: account.accountUuid,
      awsAccountId: account.provider?.aws?.awsAccountId,
      createAt: account.createAt ? new Date(account.createAt) : undefined,
    }));

    const sortModel = orderType &&
      orderBy && [
        {
          field: orderBy,
          sort: orderType === OrderByEnum.Desc ? 'desc' : ('asc' as GridSortDirection),
        },
      ];

    return (
      <Layout>
        <div className={classes.root}>
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
            <Link
              data-testid="navigate-to-dashboard"
              component={RouterLink}
              to={AppRoutePath.Dashboard}
              color="text.primary"
              underline="hover"
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            </Link>
            <Typography color="text.primary">{t('accounts')}</Typography>
          </Breadcrumbs>

          <div className={classes.topLine}>
            <Button
              data-testid="refresh-button"
              variant="contained"
              color="primary"
              className={classes.button}
              startIcon={<RefreshIcon />}
              onClick={this.onClickRefreshBtn}
            >
              {t('refresh')}
            </Button>
          </div>

          <div className={classes.dataGridWrapper}>
            <DataGrid
              rows={items}
              columns={columns}
              components={{
                Toolbar: CustomDataGridToolbar,
              }}
              componentsProps={{
                toolbar: {
                  value: searchText,
                  placeholder: t('accounts_search_placeholder'),
                  isQuickSearch: true,
                  onChange: (event) => this.onSearchInputChange(event.target.value),
                  clearSearch: () => this.onSearchInputChange(''),
                },
              }}
              classes={{ root: classes.gridRoot }}
              localeText={{
                toolbarColumns: t('data_grid.columns'),
                toolbarFilters: t('data_grid.filters'),
                toolbarDensity: t('data_grid.density'),
                filterPanelColumns: t('data_grid.columns'),
                filterPanelOperators: t('data_grid.operators'),
                columnsPanelTextFieldLabel: t('data_grid.find_column'),
                columnsPanelTextFieldPlaceholder: t('data_grid.column_title'),
                columnsPanelDragIconLabel: t('data_grid.reorder_column'),
                columnsPanelShowAllButton: t('data_grid.show_all'),
                columnsPanelHideAllButton: t('data_grid.hide_all'),
                toolbarDensityCompact: t('data_grid.density_compact'),
                toolbarDensityStandard: t('data_grid.density_standard'),
                toolbarDensityComfortable: t('data_grid.density_comfortable'),
                filterOperatorContains: t('data_grid.contains'),
                filterOperatorEquals: t('data_grid.equals'),
                filterOperatorStartsWith: t('data_grid.startsWith'),
                filterOperatorEndsWith: t('data_grid.endsWith'),
                filterOperatorIsEmpty: t('data_grid.isEmpty'),
                filterOperatorIsNotEmpty: t('data_grid.isNotEmpty'),
                filterOperatorIsAnyOf: t('data_grid.isAnyOf'),
                filterOperatorIs: t('data_grid.is'),
                filterOperatorNot: t('data_grid.isNot'),
                noRowsLabel: t('data_grid.no_rows'),
              }}
              isRowSelectable={() => false}
              checkboxSelection={false}
              loading={isLoading}
              paginationMode="server"
              rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
              rowCount={totalItems || 0}
              pageSize={pageSize}
              page={page}
              onPageChange={this.onPageChange}
              onPageSizeChange={this.onPageSizeChange}
              sortingMode="server"
              sortingOrder={DATA_GRID_SORTING_ORDER}
              sortModel={sortModel}
              onSortModelChange={this.onSortModelChange}
              disableSelectionOnClick
            />
          </div>
        </div>
      </Layout>
    );
  }

  private onSortModelChange = (model: GridSortModel) => {
    this.props
      .listAccounts({
        order: model[0] && {
          orderBy: model[0].field as ListAccountsOrderEnum,
          orderType: model[0].sort === 'asc' ? OrderByEnum.Asc : OrderByEnum.Desc,
        },
        page: { pageIndex: 0 },
      })
      .catch(console.log);
  };

  private onPageSizeChange = (pageSize) => {
    this.props.listAccounts({
      page: { pageIndex: 0, pageSize },
    });
  };

  private onPageChange = (page) => {
    this.props.listAccounts({
      page: { pageIndex: page },
    });
  };

  private onSearchInputChange = (value) => {
    this.setState({
      searchText: value,
    });
    this.debouncedOnSearchInputChange(value);
  };

  private debouncedOnSearchInputChange = _.debounce((searchText: string) => {
    const { listAccounts } = this.props;
    listAccounts({
      filter: { searchText },
      page: { pageIndex: 0 },
    }).catch(console.log);
  }, SEARCH_INPUT_DELAY);

  onClickRefreshBtn = () => {
    const { listAccounts } = this.props;
    this.setState({
      searchText: '',
    });
    listAccounts({
      filter: {
        searchText: '',
      },
      order: {
        orderBy: DataGridFieldEnum.CREATED_AT,
        orderType: OrderByEnum.Desc,
      },
      page: { pageIndex: 0 },
    }).catch(console.log);
  };
}

const styles = (theme) =>
  createStyles({
    root: {
      width: '100%',
      margin: 'auto',
      paddingRight: 0,
    },
    dataGridWrapper: {
      height: 500,
      width: '100%',
      marginTop: 24,
      backgroundColor: whiteColor,
      [theme.breakpoints.up('sm')]: {
        marginTop: 32,
      },
      '& .MuiDataGrid-cell:focus': {
        outline: 'none',
      },
    },
    gridRoot: {
      '& .MuiDataGrid-row': {
        maxHeight: 'none !important',
      },
      '& .MuiDataGrid-cell': {
        whiteSpace: 'pre-wrap',
        maxHeight: 'none !important',
        outline: 'none !important',
      },
      '& .MuiDataGrid-cell:focus': {
        outline: 'none',
      },
      '& .MuiDataGrid-columnHeaderTitleContainer': {
        padding: 0,
      },
    },
    topLine: {
      marginTop: 20,
      display: 'flex',
      justifyContent: 'end',
    },
    button: {
      margin: 0,
    },
  });

const mapStateToProps = (store: IStore) => ({
  accountList: store.appState.accountList,
  isLoading: AccountActions.listAccounts.isPending(store),
});

const mapDispatchToProps = (dispatch): IDispProps => ({
  listAccounts: (args: AccountActions.QueryListAccountsArgs) =>
    dispatch(AccountActions.listAccounts(args)),
});

export default compose(
  withFirebaseUser(),
  withAdminProfile,
  withAuthorization(['owner', 'admin', 'operator', 'billing_operator']),
  withStyles(styles),
  withTranslation('admin'),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(Accounts);

export type Item = {
  id: string;
  accountUuid: string;
  awsAccountId?: string;
  displayName: string;
  email: string;
  serverNum: number;
  createAt?: Date;
};

export enum DataGridFieldEnum {
  EMAIL = 'email',
  PROVIDER = 'provider',
  AWS_ACCOUNT_ID = 'awsAccountId',
  DISPLAY_NAME = 'displayName',
  UUID = 'uuid',
  SERVER_NUM = 'serverNum',
  CREATED_AT = 'createAt',
}
